import { Omit } from '../../utils/types/omit';
import { IAddress } from '../general/address';
import {
    IBffEmployee,
    IEmployee,
    IEmployeeDetails,
    IFunction,
    IMedicalCenter,
    IPerson,
} from '../admin/employee';
import { ICmsImage } from '../general/cms';
import { ICompany } from '../admin/company';
import { ICompanyContactToAdd } from '../admin/companyInfo';
import { IDocument } from '../general/documents';
import { IEmployeePlannedMedicalExamination } from '../admin/employee/medical-examination';
import { ILocalePayload } from '../general/i18n';
import { IStartEndDateFilterValues } from '../ui/form';
import { IStepperStep } from '../general/stepper';
import { ITraceableAsyncRequestPayload } from '../general/redux';

import { IPlannedMedicalExamination } from './medicalExaminations/planned';
import { IReservedMedicalExamination } from './medicalExaminations/reserved';
import { ITimeSlotBase } from './timeslots';

export interface IPlannedMedicalExaminationsFilter {
    startDate?: string; // format 2018-01-01
    endDate?: string; // format 2018-01-01
    medicalCenterCode?: string;
}

export interface IFetchMedicalExaminationDetailPayload {
    id?: number; // Employee id
    examinationId: number;
}

export interface IFetchPlannedMedicalExaminationDetailPayload {
    id?: number; // Employee id
    timeSlotId: number;
}

export type TExaminationMedicalCenter = Pick<IMedicalCenter, 'id' | 'name'> & {
    address: Pick<IAddress, 'postcode' | 'city' | 'street'>,
};

export interface IExaminationReason {
    id: number;
    code: string;
    title: string;
    infoText?: string;
    duration?: number;
    regularIcon?: ICmsImage;
    hoverIcon?: ICmsImage;
    colour?: string;
    active: boolean;
}

export interface IBffExaminationReason extends Pick<IExaminationReason, 'code'> {
    description: string
}

export interface IMedicalExaminationToPlanApiResponse {
    employee: {
        id: number;
        employeeId: number;
        firstName: string;
        name: string;
        birthDate: string;
        email?: string;
        dateOutOfService?: string;
    };
    company: {
        name: string;
        companyCode: string;
        id?: number;
    };
    function: {
        id: number;
        description: string;
    };
    planningRecordId: number;
    medicalCenter: {
        id: number;
        name: string;
        code: string;
    };
    examinationReason: {
        id: number;
        title: string;
        code: string;
    };
    duration: number;
    rescheduleAbsent: boolean;
    toBePlannedDate: string;
    toBePlanned: boolean;
    absent: boolean;
    absentDescription: string;
    periodical?: boolean;
}

export interface IMedicalExaminationToPlan extends Omit<
    IMedicalExaminationToPlanApiResponse,
    'planningRecordId'
> {
    planningRequestId: number;
}

export interface ITriggerPlanMedicalExaminationWizardProps {
    wizardType: PLAN_MEDICAL_EXAMINATION_WIZARD_TYPE;
    reason: IExaminationReason;
}

export interface IPlanMedicalExaminationWizardPayload
    <WizardType = PLAN_MEDICAL_EXAMINATION_WIZARD_TYPE, Step = string> {
    wizardType: WizardType;
    step?: Step;
    reason?: IExaminationReason;
    resetDataEntity?: boolean;
    skipAllEpicSideEffects?: boolean;
    action?: PLAN_MEDICAL_EXAMINATION_WIZARD_ACTION;
    skipToStep?: boolean;
}

export interface ISkipToPlanMedicalExaminationWizardStepPayload {
    entity?: IPlanMedicalExaminationSingleEmployeeBaseEntity;
    wizardPayload: Pick<IPlanMedicalExaminationWizardPayload, 'wizardType' | 'reason' | 'step'>;
}

export enum PLAN_MEDICAL_EXAMINATION_WIZARD_ACTION {
    exit = 'exit',
}

export enum PLAN_MEDICAL_EXAMINATION_WIZARD_TYPE {
    PERIODIC_HEALTH_ASSESSMENT_MANUAL = 'po-manual', // po = reason 'periodiek onderzoek'
    PERIODIC_HEALTH_ASSESSMENT_AUTOMATIC = 'po-bulk',
    INTERIM_MANUAL = 'interim',
    RE_INTEGRATION_MANUAL = 're-integration',
    RESUMPTION_OF_WORK_MANUAL = 'resumption-of-work',
    OTHER_MANUAL = 'other', // reasons 'moederschap' or 'aanwerving'
    MOVE_PLANNING = 'move-planning',
}

/**
 * Unique combination of examinationReason + wizardType
 * (so that in analytics a separate funnel can be configured per combo)
 */
export enum PLAN_MEDICAL_EXAMINATION_FUNNEL_TYPE {
    PERIODIC_HEALTH_ASSESSMENT_MANUAL = 'po-manual',
    PERIODIC_HEALTH_ASSESSMENT_AUTOMATIC = 'po-bulk',
    INTERIM_MANUAL = 'interim',
    RE_INTEGRATION_MANUAL = 're-integration',
    RESUMPTION_OF_WORK_MANUAL = 'resumption-of-work',
    MOVE_PLANNING = 'move-planning',
    MATERNITY_PROTECTION = 'maternity-protection',
    SPONTANEOUS_CONSULTATION = 'spontaneous',
    PERIODIC_CHECK_PLUS_SAFETY_FUNCTION = 'po-and-safety-function',
    PRIOR_HEALTH_ASSESSMENT_AT_RECRUITMENT = 'recruitment',
    OTHER_MANUAL = 'other', // when unexpected examinationReason
}

/**
 * Contains all possible steps regardless of the wizard type!
 * Only those interim steps that are re-used from the add-employee steps, are not in here.
 */
export enum PLAN_MEDICAL_EXAMINATION_WIZARD_STEP_ID {
    AUTO_NOTIFY = 'auto-notify',
    AUTO_START_DATE = 'auto-start-date',
    AUTO_TIME = 'auto-time',
    AUTO_VALIDATE = 'auto-validate',
    EMPLOYEES_TO_PLAN = 'employees-to-plan',
    MANUAL_NOTIFY = 'manual-notify',
    MANUAL_TIME = 'manual-time',
    RESUMPTION_ABSENCE = 'resumption-absence',
    SEARCH_EMPLOYEE = 'search-employee',
    RE_INTEGRATION_DETAILS = 're-integration-details',
    RE_INTEGRATION_VALIDATE = 're-integration-validate',
    CONTACT_PERSON_MANUAL = 'contact-person-manual',
    WORK_POST_CARD = 'work-post-card',
}

export interface IMedicalExaminationToAdd {
    employee: {
        id: number;
        employeeId: number;
        email?: string;
        dateOutOfService?: string;
    } & IPerson;
    company: {
        name: string;
        companyCode: string;
        id?: number;
    };
    function: {
        id: number;
        description: string;
    };
    examinationReason: {
        id: number;
        title: string;
        code: string;
    };
    planningRequestId?: number;
    duration: number;
    toBePlannedDate?: string;
    oldTimeSlotId?: string;
    errorMessage?: string;
    timeSlot?: ITimeSlotBase | IMedicalExaminationTimeSlotWithoutType;
    location?: {
        name: string;
        code: string;
        id?: number;
    };
    startDate?: string;
    startTime?: string;
    endTime?: string;
    timeSlotId?: number; // for convocation purposes
    absent?: boolean;
}

// TODO: Update planned medical examination: fix planned medical examination
export interface IMovePlanningData {
    timeSlot: IMedicalExaminationTimeSlotWithoutType;
    planningRequestId: number;
}

export interface ISelectTimeslotFilter {
    selectedDate: string;
    selectedMedicalCenterCode: string;
    dateRangeToFetch: {
        start: string;
        end: string;
    };
}

export interface IPlanMedicalExaminationBaseEntity {
    selectTime: {
        filter?: Partial<ISelectTimeslotFilter>;
        minDate?: string;
        maxDate?: string;
        selectedEventId?: string;
        selectedTimeslot?: IMedicalExaminationTimeSlot;
        remarks?: string;
        timeSlotId?: number; // used for convocation purposes
    };
    movePlanning?: IMovePlanningData;
    selectedPlannedExaminations?: IPlannedMedicalExamination[];
    selectedMedicalExaminationToPlan?: IMedicalExaminationToPlan; // when triggered from To Plan list
}

export interface IPlanMedicalExaminationSingleEmployeeBaseEntity<
    Employee = Pick<IEmployee, 'id' | 'employeeId' | 'name' | 'firstName'>
    > extends IPlanMedicalExaminationBaseEntity {
    searchEmployee: {
        autoSelected?: boolean;
        searchValue: string;
        selectedEmployee: Employee;
    };
}

export interface IPlanMedicalExaminationMultipleEmployeesBaseEntity extends IPlanMedicalExaminationBaseEntity {
    medicalExaminationsToAdd: IMedicalExaminationToAdd[];
    addedEmployees: IMedicalExaminationToPlan[];
}

export interface IPeriodicHealthAssessmentManualEntity extends IPlanMedicalExaminationSingleEmployeeBaseEntity { }

export interface IPeriodicHealthAssessmentAutomaticEntity extends IPlanMedicalExaminationMultipleEmployeesBaseEntity {
    startDate?: string;
    startTime?: string;
    endTime?: string;
    addedLocationIds?: number[];
    locationFreeSlots?: IMedicalCenterFreeSlots[];
}

export interface IPlanMedicalExaminationManualContactBaseEntity
    extends IPlanMedicalExaminationSingleEmployeeBaseEntity<Pick<IEmployee,
        'id' | 'employeeId' | 'name' |
        'firstName' | 'nationalRegisterNumber' |
        'function' | 'company'>> {
    contact?: Pick<ICompanyContactToAdd,
        'firstName' |
        'name' |
        'typeId' |
        'email' |
        'phone'>;
}

export interface IReintegrationManualEntity extends IPlanMedicalExaminationManualContactBaseEntity {
    certificate?: IDocument;
    startDate?: string;
}

export interface IRequestReIntegrationPayload {
    id: number; // employee customer id
    employee: {
        nationalRegisterNumber: string;
        function: IFunction;
        company: Pick<ICompany,
            'companyCode'>;
    } & IPerson;
    contact: Pick<ICompanyContactToAdd,
        'firstName' |
        'name' |
        'typeId' |
        'email' |
        'phone'>;
    startDate: string;
    attachmentBodyBase64: string;
    attachmentFileName: string;
}

export interface IResumptionOfWorkManualEntity extends IPlanMedicalExaminationSingleEmployeeBaseEntity {
    absent?: boolean;
    startDate?: string;
}

export interface IOtherReasonManualEntity extends IPlanMedicalExaminationSingleEmployeeBaseEntity { }

export interface IInterimManualEntity
    extends IPlanMedicalExaminationSingleEmployeeBaseEntity<Partial<IEmployeeDetails>> {
    workPostCard?: File;
}

// TODO add entity types per wizard type
export type TPlanMedicalExaminationWizardEntity =
    IPeriodicHealthAssessmentManualEntity
    | IPeriodicHealthAssessmentAutomaticEntity
    | IReintegrationManualEntity
    | IResumptionOfWorkManualEntity
    | IOtherReasonManualEntity;

export interface IPlanMedicalExaminationWizardData<Entity = TPlanMedicalExaminationWizardEntity> {
    wizardType: PLAN_MEDICAL_EXAMINATION_WIZARD_TYPE;
    stepId: string;
    entity: Partial<Entity>;
    examinationReason: IExaminationReason;
}

export interface IExecutedMedicalExaminationsFilter {
    startDate?: string; // format 2018-01-01
    endDate?: string; // format 2018-01-01
}

export interface IExecutedMedicalExamination {
    id: number;
    employee: {
        id: number,
        employeeId: number,
        company: Pick<ICompany, 'name' | 'companyCode'>,
        function: IFunction;
    } & IPerson;
    examinationReason: Pick<IExaminationReason, 'id' | 'code' | 'title'>;
    examinationDate: string; // example: "2017-10-10"
    validUntil: string; // example: "2017-10-10"
    conclusionCodeId: number;
    medicalCenter: Pick<IMedicalCenter, 'name' | 'id'>;
    conclusionPregnancy: string;
    conclusionPregnancySentences: string;
    conclusionSigns: string;
    conclusionRemarks: string;
    conclusion: string;
    duration?: number;
    startTime?: string;
    confidential: string;
    isFinal: boolean;
}

export interface IMedicalCenterFreeSlotsPayload extends ITraceableAsyncRequestPayload {
    medicalCenterData: IMedicalCenterFreeSlotsPayloadData;
}

interface IMedicalCenterFreeSlotsPayloadData {
    startTime: string;
    endTime: string;
    startDate: string;
    endDate?: string;
    company: Pick<ICompany, 'companyCode'>;
    medicalCenters: Pick<IMedicalCenter, 'id'>[];
    examinationReason: Pick<IExaminationReason, 'id'>;
}

export interface IMedicalCenterFreeSlots {
    id: number;
    freeSlots: number;
}

export interface IMedicalCentersFreeSlotsSuccessPayload extends ITraceableAsyncRequestPayload {
    medicalCenters: IMedicalCenterFreeSlots[];
}

export interface IFetchMedicalCenterTimeslotsByEmployeePayload extends Partial<IStartEndDateFilterValues> {
    employeeId: number;
    medicalCenterCode: string;
    employmentId: number;
    showFullFamily?: boolean;
    examinationReasonCode?: string;
    planningRequestId?: number;
}

export interface IFetchMedicalCenterTimeslotsByEmployeeApiPayload
    extends IFetchMedicalCenterTimeslotsByEmployeePayload, ILocalePayload {
        companyCode: string;
        showFullFamily: boolean;
    }

export interface IMedicalExaminationDocuments {
    [documentClassId: string]: IMedicalExaminationDocument[];
}

export interface IMedicalExaminationDocument {
    id: number;
    templateName: string;
}

export interface IFetchMedicalExaminationDocumentsPayload {
    examinationId: number;
    employeeCustomerId: number;
}

export interface INoShowMedicalExamination {
    employee: IPerson;
    reasonAbsent: string;
    planningsType: string;
    planningsDate: string; // example: "2017-10-10"
    company: Pick<ICompany, 'companyCode' | 'name'>;
}

export interface IFetchNoShowsMedicalExaminationsPayload {
    companyCode: string;
    startDate: string;
    endDate: string;
    showFullFamily: boolean;
}

export interface INoShowsMedicalExaminationsFilter {
    startDate?: string; // format 2018-01-01
    endDate?: string; // format 2018-01-01
}

export interface IPlanMedicalExaminationWizardStep extends IStepperStep {
    hide?: (entity?: TPlanMedicalExaminationWizardEntity | IInterimManualEntity) => boolean;
}

// Validate employee to plan
export interface IValidateEmployeeToPlanPayload {
    employeeToPlan: Pick<IEmployee, 'id'>;
    examinationReason: {
        id: number;
        code?: string;
    };
    onValidationPassed: () => void;
}

export interface IValidateEmployeeToPlanData {
    warning: false | {
        isNotToBePlanned: boolean;
        hasAlreadyPlannedMedicalExamination: boolean;
        plannedMedicalExaminations?: IEmployeePlannedMedicalExamination[];
        canWarningBeOverruled: boolean;
    };
}

export interface IRequestMedicalExaminationPayload {
    weekDays: {
        monday: boolean;
        tuesday: boolean;
        wednesday: boolean;
        thursday: boolean;
        friday: boolean;
        saturday: boolean;
        sunday: boolean;
    };
    planningPreference: string;
    preferredTime: string;
    nameEmployee: string;
    birthDateEmployee: string;
    medicalCenterName: string;
    examinationReason: string;
    contactName: string;
    contactPhoneNumber: string;
}

export enum MedicalExaminationTimeSlotType {
    Free = 'Free',
    Reserved = 'Reserved'
}

export interface IMedicalExaminationTimeSlot extends ITimeSlotBase {
    type: MedicalExaminationTimeSlotType,
    activityId: number;
}

export interface IMedicalExaminationTimeSlotWithoutType extends ITimeSlotBase {
    activityId: number;
}

export interface IBufferzonePlannedMedicalExamination
    extends Pick<
        IPlannedMedicalExamination,
        'end' | 'start' | 'examinationReason' | 'planningRequestId' | 'remarks' | 'timeSlotId' | 'isEditable'
    > {
    employee: IBffEmployee;
}

export interface IPlannedTimeSlot extends
    IBufferzonePlannedMedicalExamination,
    Pick<
        IReservedMedicalExamination,
        'location' | 'doctor' | 'branch'
    > {}

export interface IBffMedicalExaminationReservationAvailabilty {
    plannableTimeSlots: number;
    totalTimeSlots: number;
}
